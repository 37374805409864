<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col class="d-flex justify-content-around" cols="6">
            <b-form-group label="Дата, от">
              <b-input-group>
                <b-form-input
                  v-model="filters.date_document_start"
                  placeholder="ГГГГ-ММ-ДД"
                  type="text"
                />
                <b-input-group-append>
                  <b-datepicker
                    v-model="filters.date_document_start"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :max="max"
                    button-only
                    reset-button
                    right
                    size="sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Дата, до">
              <b-input-group>
                <b-form-input
                  v-model="filters.date_document_end"
                  placeholder="ГГГГ-ММ-ДД"
                  type="text"
                />
                <b-input-group-append>
                  <b-datepicker
                    v-model="filters.date_document_end"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :max="max"
                    :min="filters.date_document_start"
                    button-only
                    reset-button
                    right
                    size="sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="d-flex justify-content-center" cols="3">
            <b-button @click="getPercentDistributionExcel">
              Месячные проценты
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="3">
            <b-button @click="getDealDistributionExcel">
              Распределение платежей
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="3">
            <b-button @click="getCarPriceReport">
              Оценочная стоимость авто
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="3">
            <b-button @click="getUserReport">
              Клиенты (Адреса и Документы)
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Downloads',
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    return {
      filters: {
        date_document_start: null,
        date_document_end: null,
      },
      loading: false,
      max: maxDate,
    }
  },
  computed: {
    start() {
      return this.filters.date_document_start || undefined
    },
    end() {
      return this.filters.date_document_end || undefined
    },
  },

  created() {},
  methods: {
    async getPercentDistributionExcel() {
      const res = await this.$http.get(
        'admin/report/deal-monthly-percent-excel/',
        {
          params: {
            start: this.start,
            end: this.end,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Месячные проценты')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },

    async getDealDistributionExcel() {
      const res = await this.$http.get(
        'admin/report/deal-distribution-excel/',
        {
          params: {
            start: this.start,
            end: this.end,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Распределение платежей')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },

    async getCarPriceReport() {
      const res = await this.$http.get('admin/report/car-price-excel/', {
        responseType: 'blob',
      })
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Оценочная стоимость авто')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },

    async getUserReport() {
      const res = await this.$http.get('admin/report/user-excel/', {
        responseType: 'blob',
      })
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Клиенты')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },
  },
}
</script>
